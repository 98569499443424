
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonText,
  IonButton,
  IonBadge,
  IonRippleEffect,
  IonIcon,
  alertController,
  modalController,
  toastController,
  IonSpinner,
} from "@ionic/vue";
import PageHeader from "@/components/partial/PageHeader.vue";
import {
  checkmarkCircleOutline,
  chevronForward,
  pencilSharp,
} from "ionicons/icons";
import LocationOrder from "@/components/partial/LocationOrder.vue";

import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import UpdatePassword from "@/components/partial/UpdatePassword.vue";
import RenderedCompoVue from "./RenderedCompo.vue";

export default defineComponent({
  name: "Profile",

  components: {
    IonPage,
    IonContent,
    IonText,
    IonButton,
    IonBadge,
    IonRippleEffect,
    IonIcon,
    PageHeader,
    IonSpinner,
  },

  head: {
    title: {
      inner: "It will be a pleasure",
    },
  },

  setup() {
    return { checkmarkCircleOutline, chevronForward, pencilSharp };
  },

  // mounted() {
  //   console.log(this.$refs.fotoProfile);

  // },

  computed: {
    ...mapGetters("user", {
      user: "getUser",
    }),
  },

  data() {
    return {
      previewImage: null,
      imageProfile: null,
      menus: [
        {
          key: "address",
          title: "Biodata & Alamat Penerima",
        },
        {
          key: "trxHistory",
          title: "Riwayat transaksi",
        },
        {
          key: "ubahPass",
          title: "Ubah Password",
        },
        {
          key: "toc",
          title: "Term & condition",
        },
        {
          key: "privacy",
          title: "Ketentuan privasi",
        },
        {
          key: "faq",
          title: "FAQ",
        },
        {
          key: "about",
          title: "Tentang",
        },
        {
          key: "logout",
          title: "Keluar",
        },
      ],
    };
  },

  methods: {
    ...mapActions("user", {
      fetchUser: "fetchUser",
    }),
    async uploadImageFoto($event) {
      const file = $event.target.files[0];
      if (!file) return;

      this.previewImage = URL.createObjectURL(file);
      this.imageProfile = file;

      this.loadingImage = true;
      const formdata = new FormData();
      formdata.append("photo", this.imageProfile);
      await axios
        .post("change-photo", formdata)
        .then(async ({ data }) => {
          const toast = await toastController.create({
            message: data.message,
            duration: 2000,
          });
          this.previewImage = null;
          this.loadingImage = false;
          toast.present();
          return this.fetchUser();
        })
        .catch(async ({ response }) => {
          this.loadingImage = false;
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2000,
          });
          this.previewImage = null;
          return toast.present();
        });
    },
    addClass() {
      console.log("add class");
    },
    removeClass() {
      console.log("delete class");
    },
    async updatePasswordModal() {
      const modal = await modalController.create({
        component: UpdatePassword,
        componentProps: {
          data: null,
        },
      });
      return modal.present();
    },
    async runFunction(key: any) {
      if (key === this.menus[0].key) {
        this.openModalProfileSetting();
      } else if (key === this.menus[1].key) {
        this.$router.replace("/main/order");
      } else if (key === this.menus[2].key) {
        this.updatePasswordModal();
      } else if (key === this.menus[3].key) {
        // term condition
        const modal = await modalController.create({
          component: RenderedCompoVue,
          componentProps: {
            data: {
              title: 'Term & Condition',
              key: 'term'
            }
          }
        });
        return modal.present()
      } else if (key === this.menus[4].key) {
        // Ketentuan privasi
        const modal = await modalController.create({
          component: RenderedCompoVue,
          componentProps: {
            data: {
              title: 'Ketentuan Privasi',
              key: 'ketentuan'
            }
          }
        });
        return modal.present()
      } else if (key === this.menus[5].key) {
        // FAQ
        const modal = await modalController.create({
          component: RenderedCompoVue,
          componentProps: {
            data: {
              title: 'FAQ',
              key: 'faq'
            }
          }
        });
        return modal.present()
      } else if (key === this.menus[6].key) {
        // Tentang
        const modal = await modalController.create({
          component: RenderedCompoVue,
          componentProps: {
            data: {
              title: 'Tentang',
              key: 'tentang'
            }
          }
        });
        return modal.present()
      } else if (key === "logout") {
        const alert = await alertController.create({
          header: "Keluar Akun",
          message: "Apakah Anda Yakin Ingin Keluar Akun?",
          buttons: [
            {
              text: "Tidak",
            },
            {
              text: "Ya, Keluar",
              handler: () => this.logout(),
            },
          ],
        });
        alert.present();
      }
    },
    async openModalProfileSetting() {
      const modal = await modalController.create({
        component: LocationOrder,
        componentProps: {
          info: this.user,
          profile: true,
        },
      });
      modal.present();
      modal.onDidDismiss().then(() => {
        this.fetchUser();
      });
    },
    logout() {
      window.$cookies.remove("accessToken");
      window.location.replace("/auth/login");
    },
  },
});
