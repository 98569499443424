<template>
  <ion-content :fullscreen="true">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href @click="closeModal"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ data.title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <div
      class="flex"
      style="
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      "
      v-if="loading"
    >
      <ion-spinner style="width: 3em; height: 3em"></ion-spinner>
    </div>
    <div v-else class="p-4" v-html="content"></div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  modalController,
  IonSpinner,
  toastController,
} from "@ionic/vue";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RenderedCompo",
  props: {
    data: { type: Object, default: null },
  },
  data() {
    return {
      content: null,
      loading: false,
      urlComp: [
        {
          key: "term",
          url: "term-condition", // https://indojual.com/API/term-condition
        },
        {
          key: "ketentuan",
          url: "ketentuan-privasi", // https://indojual.com/API/ketentuan-privasi
        },
        {
          key: "faq",
          url: "faq", // https://indojual.com/API/faq
        },
        {
          key: "tentang",
          url: "tentang", // https://indojual.com/API/tentang
        },
      ],
    };
  },
  components: {
    IonContent,
    IonHeader,
    IonSpinner,
  },
  mounted() {
    this.getCompo();
  },
  methods: {
    async getCompo() {
      const filtered = this.urlComp.filter((e) => e.key == this.data.key)[0];
      this.loading = true;
      await axios
        .get(filtered.url)
        .then(({ data }) => {
          this.loading = false;
          this.content = data.data;
        })
        .catch(async ({ response }) => {
          this.loading = false;
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2500,
          });
          return toast.present();
        });
    },
    async closeModal() {
      await modalController.dismiss();
    },
  },
});
</script>