<template>
  <div class="map centerMarker" ref="mapDivRef"></div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Gmaps",
  props: {
    center: {
      lat: Number,
      lng: Number,
    },
    zoom: Number,
    marker: Object,
    mapDidLoad: Function,
    coordinateLoad: Function,
  },
  setup(props) {
    const store = useStore();
    const map = ref();
    const mapDivRef = ref();
    const batamLatLng = { lat: 1.0079988, lng: 104.0261957 }; // batam iland
    let markers = null;
    const coordinate = ref(null);

    onBeforeMount(() => {
      const key = "AIzaSyDaUdjAHO_TjM3wZBu0eOZcwwaxpVb6XYY";

      const googleMapsScript = document.createElement("script");

      googleMapsScript.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap`
      );
      googleMapsScript.setAttribute("defer", "");
      googleMapsScript.setAttribute("async", "");
      googleMapsScript.setAttribute("id", "maps");
      if (props.marker) {
        batamLatLng.lat = parseFloat(props.marker.lat) 
        batamLatLng.lng = parseFloat(props.marker.lng)
      }

      if (!store.state.order.gmaps) {
        store.commit("order/GMAP_SET_LOADED");
        document.head.appendChild(googleMapsScript);
      }
    });

    onMounted(() => {
      if (window.google.maps) {
        window.initMap()
      }
    })

    const loadListeningMarker = () => {
      if (props.marker) {
        markers = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            props.marker.lat,
            props.marker.lng
          ),
          map: map.value,
        });
      }
      map.value.addListener("click", (mapsMouseEvent) => {
        coordinate.value = mapsMouseEvent.latLng.toJSON();
        if (markers) {
          markers.setMap(null);
        }
        markers = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            coordinate.value.lat,
            coordinate.value.lng
          ),
          map: map.value,
        });
        props.coordinateLoad && props.coordinateLoad(coordinate);
      });
    };

    window.initMap = () => {
      map.value = new window.google.maps.Map(mapDivRef.value, {
        mapTypeId: "roadmap",
        zoom: props.zoom || 10,
        disableDefaultUI: true,
        center: batamLatLng,
      });

      loadListeningMarker();
      props.mapDidLoad && props.mapDidLoad(map, window.google.maps);
    };
    return {
      mapDivRef,
      coordinate,
    };
  },
});
</script>

<style scoped>
.map {
  width: 100%;
  height: 100%;
  margin: 0;
}

body,
html,
.map .centerMarker {
  position: absolute;
  /*url of the marker*/
  background: url(http://maps.gstatic.com/mapfiles/markers2/marker.png)
    no-repeat;
  /*center the marker*/
  top: 50%;
  left: 50%;
  z-index: 1;
  /*fix offset when needed*/
  margin-left: -10px;
  margin-top: -34px;
  /*size of the image*/
  height: 34px;
  width: 20px;
  cursor: pointer;
}
</style>