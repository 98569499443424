<template>
  <ion-content :fullscreen="true">
    <div style="height: 30vh; background-color: #e9e9e9">
      <g-maps
        :zoom="marker ? 14 : 10"
        :coordinateLoad="getCurrentCoordinate"
        :marker="marker"
        :mapDidLoad="handleMapDidLoad"
      ></g-maps>
    </div>
    <div class="strip">
      <ion-text class="p-2 font-bold"> Alamat </ion-text>
    </div>
    <ion-item>
      <ion-label>Provinsi</ion-label>
      <ion-select
        v-model="provinsi"
        :interface-options="customActionSheetOptions"
        interface="action-sheet"
      >
        <ion-select-option
          v-for="(item, i) in provinsiList"
          :key="'provinsi_' + i"
          :value="item.id_prov"
        >
          {{ item.nama }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>Kabupaten/Kota</ion-label>
      <ion-select
        :disabled="kotaList.length == 0"
        v-model="kota"
        :interface-options="customActionSheetOptions"
        interface="action-sheet"
      >
        <ion-select-option
          v-for="(item, i) in kotaList"
          :key="'kota_' + i"
          :value="item.id_kab"
        >
          {{ item.nama_kab }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label>Kecamatan</ion-label>
      <ion-select
        :disabled="kecamatanList.length == 0"
        v-model="kecamatan"
        :interface-options="customActionSheetOptions"
        interface="action-sheet"
      >
        <ion-select-option
          v-for="(item, i) in kecamatanList"
          :key="'kecamatan_' + i"
          :value="item.id_kec"
        >
          {{ item.nama_kec }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item class="ion-margin-bottom">
      <ion-label position="floating">Alamat Lengkap</ion-label>
      <ion-textarea
        type="text"
        placeholder="Masukkan alamat lengkap anda"
        required
        v-model="location"
        rows="3"
        autofocus
      />
    </ion-item>
    <div class="strip">
      <ion-text class="p-2 font-bold"> Kontak </ion-text>
    </div>
    <ion-item class="ion-margin-bottom">
      <ion-label position="floating">Nama Penerima</ion-label>
      <ion-input
        v-model="nama"
        type="text"
        inputmode="text"
        placeholder="Masukan nama penerima Catering"
        required
      />
    </ion-item>
    <ion-item class="ion-margin-bottom">
      <ion-label position="floating">No. HP</ion-label>
      <ion-input
        type="text"
        v-model="no_hp"
        inputmode="tel"
        placeholder="08xxxxxxxxxx"
        required
        @keypress="$checkNumberOnly"
      />
    </ion-item>
    <ion-item v-if="profile" class="ion-margin-bottom">
      <ion-label position="floating">Pekerjaan</ion-label>
      <ion-input
        v-model="pekerjaan"
        type="text"
        inputmode="text"
        placeholder="Masukan pekerjaan anda"
        required
      />
    </ion-item>
    <div style="margin-bottom: 4rem"></div>
    <div
      style="
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        z-index: 3;
      "
    >
      <ion-button
        @click="saveAddres"
        :disabled="loading"
        expand="full"
        size="large"
        color="warning"
      >
        <ion-text> Simpan perubahan</ion-text>
      </ion-button>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonText,
  IonSelect,
  IonInput,
  IonTextarea,
  IonSelectOption,
  modalController,
  toastController,
} from "@ionic/vue";
import axios from "axios";
import { defineComponent, ref } from "vue";

import Gmaps from "./Gmaps.vue";

export default defineComponent({
  name: "LocationOrder",
  props: {
    info: { type: Object, default: null },
    profile: { type: Boolean, default: false },
  },
  setup(props) {
    const currentCoordinate = ref(null);
    const location = ref("");
    let geoCoderService = null;
    let marker = null


    if (props.info.lat && props.info.lng) {
      const coor = {
        lng: props.info.lng,
        lat: props.info.lat,
      }
      currentCoordinate.value = coor
      marker = coor
    }

    const getCurrentCoordinate = (coor) => {
      currentCoordinate.value = coor.value;
    };

    const findLocation = () => {
      geoCoderService.geocode(
        {
          address: location.value,
        },
        (result, status) => {
          if (status !== "OK") {
            // alert("no result")
          } else {
            console.log(result);
          }
        }
      );
    };

    const handleMapDidLoad = (map, googleService) => {
      geoCoderService = new googleService.Geocoder();
    };
    

    return {
      marker,
      handleMapDidLoad,
      getCurrentCoordinate,
      currentCoordinate,
      findLocation,
      location,
    };
  },
  mounted() {
    this.getProvinsi();
    this.info.nama && (this.nama = this.info.nama);
    this.info.hp && (this.no_hp = this.info.hp);
    this.info.alamat && (this.alamat = this.info.alamat);
  },
  data() {
    return {
      portionCount: 0,
      jumlah: 0,
      loading: false,
      provinsi: null,
      provinsiList: [],
      kota: null,
      kotaList: [],
      kecamatan: null,
      kecamatanList: [],
      alamat: null,
      nama: null,
      no_hp: null,
      pekerjaan: null
    };
  },
  watch: {
    provinsi() {
      this.getKota();
    },
    kota() {
      this.getKecamatan();
    },
  },
  methods: {
    async getProvinsi() {
      await axios.get("provinsi").then(({ data }) => {
        this.provinsiList = data.data;
        this.info.prov && (this.provinsi = this.info.prov);
        this.info.kota && (this.kota = this.info.kota);
        this.info.kec && (this.kecamatan = this.info.kec);
        this.info.alamat && (this.location = this.info.alamat);
        this.info.pekerjaan && (this.pekerjaan = this.info.pekerjaan);
      });
    },
    async getKota() {
      const formdata = new FormData();
      formdata.append("id_prov", this.provinsi);
      await axios.post("kota", formdata).then(({ data }) => {
        this.kotaList = data.data;
      });
    },
    async getKecamatan() {
      const formdata = new FormData();
      formdata.append("id_kab", this.kota);
      await axios.post("kecamatan", formdata).then(({ data }) => {
        this.kecamatanList = data.data;
      });
    },
    async saveAddres() {
      if (!this.currentCoordinate) {
        const toast = await toastController.create({
          message: "Silakan klik pada map untuk menentukan titik pengantaran",
          duration: 2000,
        });
        return toast.present();
      }
      const formdata = new FormData();
      formdata.append("prov", this.provinsi);
      formdata.append("kab", this.kota);
      formdata.append("kec", this.kecamatan);
      this.profile
        ? formdata.append("alamat", this.location)
        : formdata.append("alamat_pengantaran", this.location);
      this.profile
        ? formdata.append("nama", this.nama)
        : formdata.append("nama_penerima", this.nama);
      this.profile
        ? formdata.append("hp", this.no_hp)
        : formdata.append("no_hp", this.no_hp);
      !this.profile && formdata.append("trx", this.info.no_transksi);
      this.profile && formdata.append("pekerjaan", this.pekerjaan);
      this.currentCoordinate.lat &&
        formdata.append("lat", this.currentCoordinate.lat);
      this.currentCoordinate.lng &&
        formdata.append("long", this.currentCoordinate.lng);

      const url = this.profile ? "change-profile" : "update-delivery-address";

      await axios
        .post(url, formdata)
        .then(async ({ data }) => {
          if (data) {
            await modalController.dismiss();
          }
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 1500,
          });
          toast.present();
          await modalController.dismiss();
        });
    },
  },
  components: {
    IonContent,
    IonText,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonTextarea,
    "g-maps": Gmaps,
  },
});
</script>

<style>
.strip {
  background-color: #e9e9e9;
  width: 100%;
  padding: 10px;
}
</style>